<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">
        <slot name="title">
          {{ options.title ? options.title : $t('Alert!') }}
        </slot>
      </v-card-title>

      <v-card-text>
        <slot name="text">
          {{ options.text ? options.text : '' }}
        </slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="blue" text @click="onClose()">
          {{ $t('OK') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertModal',

  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    dialog: true
  }),

  methods: {
    onClose () {
      this.$emit('onCloseModal')
      this.options.onClose()
    }
  }
}
</script>
