<template>
  <v-row justify="start" align="baseline">
    <v-col>
      <h3>{{ $t('duration') }}</h3>
    </v-col>
    <v-col>
      <v-text-field
        :label="$t('minutes')"
        :value="minutes"
        @input="onChangeMinute"
        type="number"
      />
    </v-col>
    <v-col>
      <v-text-field
        class="muted"
        :label="$t('seconds')"
        :value="seconds"
        @input="onChangeSecond"
        type="number"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 },
  },

  computed: {
    minutes() {
      return Math.max(Math.floor((Number(this.value) / 1000) / 60), 0)
    },
    seconds() {
      return Math.max(Math.floor((Number(this.value) / 1000) % 60), 0)
    }
  },

  methods: {
    onChangeMinute(value) {
      const num = Math.max(Number(value), 0)
      this.$emit('input', ((num * 60) + this.seconds) * 1000)
    },
    onChangeSecond(value) {
      const num = Math.max(Number(value), 0)
      this.$emit('input', ((this.minutes * 60) + num) * 1000)
    }
  }
}
</script>

<style>
.muted {
  opacity: 0.2;
  transition: 0.4s;
}
.muted:hover,
.muted:active,
.muted:focus-visible,
.muted:focus-within,
.muted:focus {
  opacity: 1;
}
</style>
