<template>
  <div>
    <v-row class="mb-0 mt-4" v-if="title && !inline">
      <v-col align="left" class="my-0 py-0">
        <div class="section-title" id="tooltip">
          <div class="title">
            {{ $t(title) }}
          </div>

          <div class="tooltip" v-if="tooltip">
            <v-tooltip v-model="showTooltip" left>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mb-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="showTooltip = !showTooltip"
                >
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>

              <span>{{ $t(tooltip)}}</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <p>
    {{ selected }}
    </p> -->
    <template v-for="(input, idx) in activeInputs">
      <v-row :class="[inline ? 'my-4' : 'my-0']"  :key="idx">
        <v-col
          align="left"
          class="my-0 py-0"
          cols="8"
          v-if="(title || input.title) && inline"
        >
          <div v-if="title" class="title">{{ $t(title) }}</div>
          <div v-else class="title">{{ $t(input.title) }}</div>
        </v-col>
        <v-col class="my-0 py-0" style="text-align: right;">
          <div
            align="left"
            class="inputtitle"
            v-if="!inline && input.title && !['group', 'dynamic'].includes(input.type)"
          >
            {{ $t(input.title) }}
          </div>

          <!-- DEBUG: {{ input.type }} {{ input.scope }} {{ selected[input.scope] }} -->

          <v-radio-group
            v-if="input.type === 'radio' && !radiosAsDropdown"
            v-model="selected[input.scope]"
            @change="validate(input)"
            style="margin-top: 0px !important;"
            :hide-details="hideDetails"
          >
            <template v-if="input.value.constructor === Array">
              <v-radio
                v-for="(option, value) in input.value"
                :key="`${input.scope}_${value}`"
                :label="$t(option)"
                :value="Number(value)"
              ></v-radio>
            </template>
            <template v-else>
              <v-radio
                v-for="value in Object.keys(input.value)"
                :key="`${input.scope}_${value}`"
                :label="$t(input.value[value])"
                :value="Number(value)"
              ></v-radio>
            </template>
          </v-radio-group>

          <v-select
            v-else-if="input.type === 'radio' && radiosAsDropdown"
            v-model="selected[input.scope]"
            :items="input.value.map((v, i) => ({
              label: $t(v),
              value: i,
            }))"
            item-text="label"
            item-value="value"
            @change="validate(input)"
            :hide-details="hideDetails"
            dense
            outlined
          />

          <v-text-field
            v-else-if="input.type === 'date'"
            v-model="selected[input.scope]"
            type="datetime-local"
            :hide-details="hideDetails"
            outlined
          />

          <v-checkbox
            v-else-if="input.type === 'checkbox'"
            class="my-0"
            v-model="selected[input.scope]"
            :label="$t(input.value)"
            :hide-details="hideDetails"
            outlined
          />

          <v-text-field
            v-else-if="['text', 'number'].includes(input.type)"
            v-model="selected[input.scope]"
            type="text"
            :hide-details="hideDetails"
            outlined
            :placeholder="$t(input.title ? input.title : '')"
          />

          <v-textarea
            v-else-if="input.type === 'comment'"
            v-model="selected[input.scope]"
            :hide-details="hideDetails"
            outlined
            :placeholder="$t(input.title ? input.title : 'Comment')"
          />

          <div v-else-if="input.type === 'range'">
            <v-slider
              v-model="selected[input.scope]"
              messages="[]"
              :class="{'pb-4': sliderText(input)}"
              :min="input.min"
              :max="input.max"
            >
              <template #append>
                <nobr>{{ selected[input.scope] >= 0 ? selected[input.scope] : 'KEB' }}</nobr>
              </template>

              <template #message>
                <nobr v-html="sliderText(input)"></nobr>
              </template>

            </v-slider>
          </div>

          <duration
            v-else-if="input.type === 'duration'"
            v-model="selected[input.scope]"
          />

          <div v-else-if="input.type === 'dynamic'">
            <evaluation-inputs
              :key="`${input.scope}.${dynamic}`"
              v-for="dynamic in selected[input.scope]"
              @change="onChange"
              ref="dynRefs"
              :title="`${$t(input.title)} ${dynamic}`"
              :inputs="input.inputs"
              :scope="input.scope"
              :values="values"
              :dynamic="dynamic"
            />

            <v-row class="mb-4">
              <v-col align="left">
                <v-btn
                  elevation="0"
                  color="cyan"
                  class="block white--text"
                  :large="$vuetify.breakpoint.name !== 'xs'"
                  @click="addDynamic(input.scope)"
                >
                  {{ $t('Add') }} {{ $t(input.scope) }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  elevation="0"
                  color="red"
                  class="block white--text"
                  :large="$vuetify.breakpoint.name !== 'xs'"
                  @click="removeDynamic(input.scope)"
                >
                  {{ $t('Remove') }} {{ $t(input.scope) }}
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <evaluation-inputs
            v-else-if="input.type === 'group'"
            @change="onChange"
            ref="dynRefs"
            :title="$t(input.title)"
            :inputs="input.inputs"
            :scope="input.scope"
            :values="values"
          />

          <evaluation-inputs
            v-else-if="input.type === 'chase_select'"
            @change="onChange"
            ref="dynRefs"
            :title="$t(input.title)"
            :inputs="chaseInputs(input)"
            :scope="input.scope"
            :values="values"
          />

          <div
            v-else-if="input.type === 'label'"
            :class="input.class"
          >{{ input.value }}</div>

          <v-text-field
            v-else
            v-model="selected[input.scope]"
            outlined
          />

          <v-textarea
            v-if="input.comment && (input.type !== 'checkbox' || selected[input.scope])"
            v-model="comment"
            outlined
            :placeholder="$t(input.placeholder ? input.placeholder : 'Comment')"
          />

          <span class="red--text font-weight-bold float-left text-left" v-if="warning[input.scope]">
            {{ warning[input.scope] }}
          </span>
        </v-col>
      </v-row>

      <v-divider v-if="inline" :key="`divider-${idx}`" />
    </template>

    <v-divider v-if="divider && !inline" />
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment';

import Duration from './duration';

export default {
  name: 'evaluation-inputs',

  components: {
    Duration,
  },

  props: {
    title: { type: String },
    tooltip: { type: String },
    inputs: { type: Array, default: () => [] },
    values: { type: Array, default: () => [] },
    registration: { type: Object, default: null },
    radiosAsDropdown: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    divider: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    scope: String,
    dynamic: Number,
  },

  data: () => ({
    comment: '',
    showTooltip: false,
    selected: {},
    warning: {},
  }),

  created () {
    this.activeInputs.filter(i => !i.type.includes(['chase_select', 'group'])).forEach((i) => {
      Vue.set(this.selected, i.scope, this.getValue(i))
    })
  },

  computed: {
    checkRules () {
      return this.$nosework.checkRules
    },

    activeInputs () {
      return this.inputs.filter(i => this.checkRules(i.rules, this.values))
    }
  },

  mounted() {
    this.$watch('selected', () => this.$emit('change', this.results()), { deep: true })
  },

  methods: {
    validate (input) {
      if (!this.registration) {
        this.warning[input.scope] = null;
        return;
      }

      const value = input.value[this.selected[input.scope]];
      const selected =  value.toLowerCase().replace(/\s+/, '_');

      if (!input.validate) {
        return;
      }

      const excludes = input.validate.exclude.map(
        e => e.toLowerCase().replace(/\s+/, '_'),
      );

      if (excludes.includes(selected)) {
        this.warning[input.scope] = null;
        return;
      }

      // const game = _.uniq(this.registration.rounds.map(r => {
      //   const step = r.steps.find((s) => s.state === input.validate.state);
      //   if (!step) {
      //     return null;
      //   }
      //
      //   const result = step.results.find((r) => r.type === input.validate.type);
      //
      //   if (!result) {
      //     return null;
      //   }
      //
      //   return result.value_text.toLowerCase().replace(/\s+/, '_');
      // }).filter(g => g));
      //
      // if (game.includes(selected)) {
      //   this.warning[input.scope] = null;
      //   return;
      // }

      this.warning[input.scope] = input.validate.warning;
    },
    chaseInputs(input) {
      if (!this.registration) {
        return [];
      }

      const rounds = {};
      const keys = input.inputs.map((k) => k.toLowerCase().replace(/\s+/, '_'));

      this.registration.rounds.forEach((r, roundId) => {
        const step = r.steps.find((s) => s.state === 'stop');
        if (!step) {
          return;
        }

        const result = step.results.find((r) => r.type === 'hunting_game');

        if (!result) {
          return;
        }

        const key = result.value_text.toLowerCase().replace(/\s+/, '_');
        if (key === 'other') {
          return;
        }

        if (keys.includes(key)) {
          const game = this.$t(input.inputs[keys.indexOf(key)]);
          rounds[roundId] = `${this.$t('Round')} ${roundId + 1} (${game})`;
        }
      });

      if (Object.keys(rounds).length === 0) {
        const game = this.replaceLast(
          ',',
          ' eller',
          input.inputs.filter((k) => k !== 'None').map((k) => this.$t(k)).join(', ')
        );

        return [{
          type: 'label',
          value: `Kunde inte hitta några slutförda drev för ${game}.`,
          class: 'orange--text float-left text-left',
        }];
      }

      rounds[this.registration.rounds.length] = this.$t('None');

      return [{
          scope: input.scope,
          type: 'radio',
          value: rounds,
      }]
    },

    addDynamic(scope) {
      this.selected[scope] += 1
    },

    removeDynamic(scope) {
      this.selected[scope] -= 1
    },

    sliderText(input) {
      if (input.values === undefined) {
        return ""
      }
      if (this.selected[input.scope] < 0) {
        return 'KEB'
      }

      const fallback = input.values.findIndex((v) => v.rules === undefined)
      let current = input.values.findIndex((v) => this.checkRules(v.rules, this.values, false))

      let extra = ''

      if (current === -1) {
        current = fallback
      } else {
        const selected = {}

        input.values[current].rules.forEach((r) => {
          const keys = Object.keys(r)

          keys.forEach((k) => {
            const value = this.values.find((s) => s.type === k)
            selected[k] = value ? value.value_text : ""
          })
        })

        const extras = this.replaceLast(
          ',',
          ' och',
          Object.keys(selected).map((s) => this.$t(selected[s])).join(', '),
        )
        extra = /* html */ `<span class="extra">(baserat på ${extras})</span>`
      }

      return `${input.values[current].text[this.selected[input.scope]] ?? ""}${extra}`
    },

    replaceLast(find, replace, string) {
      var lastIndex = string.lastIndexOf(find)

      if (lastIndex === -1) {
        return string
      }

      var beginString = string.substring(0, lastIndex)
      var endString = string.substring(lastIndex + find.length)

      return beginString + replace + endString
    },

    getValue (input) {
      let scope = input.scope
      if (this.dynamic && this.scope) {
        scope = `${this.scope}_${input.scope}_${this.dynamic}`
      }

      const result = this.values.find((v) => v.type === scope)
      this.comment = result !== undefined ? result.comment : ''

      if (input.inherit && result === undefined) {
        let res = this.values.find((v) => { return v.type === input.inherit})
        if(input.type === 'date' && res !== undefined) {
              if(res.value < 1000000000000) {
                res.value = moment().format('YYYY-MM-DDTHH:mm')
              } else {
                res.value = moment(res.value).format('YYYY-MM-DDTHH:mm')
              }
        }

        return res !== undefined ? res.value : ''
      }

      if (input.type === 'date') {
        if(result !== undefined && result.value < 1000000000000) {
          return moment().format('YYYY-MM-DDTHH:mm')
        }

        return result !== undefined ? moment(result.value).format('YYYY-MM-DDTHH:mm') : ''
      }

      if (input.type === 'comment') {
        return result !== undefined ? result.comment : ''
      }

      if (input.type === 'checkbox') {
        return result !== undefined ? result.value : false
      }

      if (['range', 'duration'].includes(input.type)) {
        return result !== undefined ? result.value : 0
      }

      if (input.type === 'dynamic') {
        return result !== undefined ? result.value : 1
      }

      if (input.type === 'text') {
        return result !== undefined ? result.value_text : ''
      }

      if (input.type === 'radio') {
        return result !== undefined ? result.value : input.default
      }

      return result !== undefined ? result.value : null
    },

    getTextValue (scope, value) {
      const input = this.inputs.find((i) => i.scope === scope)
      if (!input) {
        return ''
      }

      if (typeof input.value === 'object' && input.value !== null) {
        return `${input.value[value] ?? ''}`
      }

      if (Array.isArray(input.value)) {
        return `${input.value[value] ?? ''}`
      }

      return `${input.value ?? value}`
    },

    results () {
      let dynamic = []

      if (this.$refs.dynRefs && Object.values(this.$refs.dynRefs).length > 0) {
        dynamic = _.flatten(Object.values(this.$refs.dynRefs).map((dyn) => (
          dyn.results().map((r) => {
            let type = r.type
            if (r.dynamic && r.scope) {
              type = `${r.scope}_${r.type}_${r.dynamic}`
            }
              return { ...r, type }
          })
        )))
      }

      return [
        ...dynamic,
        ...Object.keys(this.selected).map((type) => {
          const scope = this.inputs.find((i) => i.scope === type)
          const scope_type = scope ? scope.type : null


          if (this.selected[type] === null) {
            return null
          }

          if (scope_type === 'chase_select') {
            return null
          }

          if (scope_type === 'text' && this.selected[type] === '') {
            return null
          }

          let value = Number(this.selected[type])
          if(scope_type == "date") {
            value = Number(new Date(this.selected[type]).getTime())
          }

          return {
            type,
            value: Number.isNaN(value) ? 0 : value,
            value_text: this.getTextValue(type, this.selected[type]) ?? '',
            comment: scope_type === 'comment' ? this.selected[type] : this.comment,
            scope: this.scope,
            dynamic: this.dynamic,
          }
        }).filter((s) => s)
      ]
    },

    onChange() {
      this.$emit('change', this.results())
    },
  },
}
</script>

<style>
.v-input__slot {
  margin: 0px
}

.v-input__slider .v-input__append-outer {
  margin-top: 1px;
  font-weight: bold;
  font-size: 18px;
  background: #1976d2;
  padding: 5px 9px;
  border-radius: 999px;
  color: #fff;
}

.v-input__slider .v-slider {
  margin-left: 0;
}

.v-input__slider .v-messages {
  min-height: auto;
}

.v-input--checkbox .v-messages {
  min-height: auto;
}

.v-messages__message .extra {
  font-style: italic;
  padding-left: 8px;
}



</style>
