<template>
  <v-dialog v-model="dialog" persistent maxWidth="600px">
    <div v-if="options.evaluation" class="white">
      <div class="pa-4 pb-1">
        <h5 class="text-h5 pt-3 pb-0">
          <slot name="icon" />
          {{ $t(options.title) }}
        </h5>
      </div>

      <evaluation-inputs
        v-if="evaluation"
        ref="evaluation"
        :inputs="evaluation.inputs"
        :title="evaluation.title"
        class="pl-4 pr-4"
      />
    </div>

    <slot name="upper" />

    <v-card-actions class="pa-4 white">
      <v-btn
        elevation="0"
        right
        text
        class="grey white--text"
        xLarge
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </v-btn>

      <v-spacer />

      <v-btn
        right
        text
        class="blue white--text"
        xLarge
        @click="onSave"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
import EvaluationInputs from '@/components/evaluation/inputs.vue'

export default {
  name: 'EvaluationModal',

  components: {
    EvaluationInputs
  },

  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    dialog: true,
  }),

  computed: {
    evaluation () {
      return this.options.evaluation
    }
  },

  methods: {
    onCancel () {
      this.$emit('onCloseModal')
      this.options.onClose(null)
    },

    onDelete () {
      this.$emit('onCloseModal')
      this.options.onClose(null)
    },

    onSave () {
      let results = []

      if (this.evaluation) {
        results = this.$refs.evaluation.results()
      }

      this.options.onClose(results)
      this.$emit('onCloseModal')
    }
  }
}
</script>
