<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    maxWidth="600px"
  >
    <v-card>
      <v-card-title>{{ $t(options.title) }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="pt-4">
          <v-col>
            <v-btn
              elevation="0"
              class="green white--text mr-4"
              xLarge
              @click="onRegisterBark"
            >
              {{ $t('Register bark') }}
            </v-btn>
            <!-- <v-btn
              elevation="0"
              :disabled="!isRunning"
              class="red white--text"
              xLarge
              @click="onStop"
            >
              {{ $t('Stop') }}
            </v-btn> -->
          </v-col>
        </v-row>
        <v-row class="pt-0">
          <v-col cols="9" class="align-end" v-if="bpm">
            <span class="grey--text">{{ resultText }}</span>
            <h2>{{ bpm }} skall per minut</h2>
          </v-col>
        </v-row>

        <v-select class="pt-4 pb-0" outlined v-model="bpmSelect" :items="[
          { text: `Välj snitt automatiskt (${bpmAverage})`, value: 0 },
          { text: `Välj manuellt`, value: 1 },
        ]">
        </v-select>

      <v-row class="align-center layout" v-if="bpmSelect === 1">
        <v-col>
        <v-text-field
          numeric
          v-model="bpmCustom"
          outlined
          hide-details
          single-line
          type="number"
        />
        </v-col>
        <v-col>
          <h2>skall per minut</h2>
        </v-col>
      </v-row>

        <v-row v-if="barks && barks.length">
          <v-list class="transparent" style="width: 100%">

            <v-list-item
              v-for="bark in barks"
              :key="bark.key"
            >
              <v-list-item-content align="left">
                <v-list-item-title>
                  {{bark.count}} skall per minut
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ moment(bark.timestamp).format('YYYY-MM-DD HH:mm') }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn @click="removeBpm(bark.key)" elevation="0" icon>
                  <v-icon color="grey lighten-1">
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-card-actions class="pa-4 white">
        <v-btn
          elevation="0"
          right
          class="grey white--text"
          xLarge
          @click="onCancel"
        >
          {{ $t('Close') }}
        </v-btn>

        <v-spacer />

        <v-btn
          right
          xLarge
          class="blue white--text"
          @click="onSave"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'BarkFrequencyModal',

  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    moment,
    dialog: true,
    isRunning: false,
    timestamp: 0,
    elapsed: 0,
    bpm: 0,
    count: 0,
    bpmSelect: 0,
  }),

  computed: {
    bpmAverage() {
      if (this.bpm > 0) {
        return Math.round(_.sum([
          this.bpm,
          ...this.barks.map(b => b.count),
        ]) / (this.barks.length + 1))
      }

      if (this.barks.length > 0) {
        return Math.round(_.sum(this.barks.map(b => b.count)) / this.barks.length)
      }

      return 0
    },

    barks () {
      return this.$store.state.rx.barks.filter(
        b => b.registration === this.registration && b.round === this.round
      ).sort((a, b) => Number(a.timestamp) - Number(b.timestamp))
    },

    registration () {
      return Number(this.$route.params.registrationId)
    },

    round () {
      return Number(this.$route.params.roundId)
    },

    resultText () {
      if (!this.isRunning) {
        return 'Tryck på registrera skall för att starta'
      }

      return `Vi har registerat ${this.count} skall under ${Math.floor(this.elapsed)} sekunder vilket ger oss.`
    },
  },

  mounted () {
    this.isRunning = false;

    if (this.options.result && this.options.result.value !== this.bpmAverage) {
      this.bpmSelect = 1
      this.bpmCustom = this.options.result.value
    } else {
      this.bpmCustom = this.bpmAverage
    }

    this.$nosework.watch('timestamp', (value) => {
      if (this.isRunning) {
        this.elapsed += (value - this.timestamp) / 1000
        this.bpm = Math.round((this.count / this.elapsed) * 60)
      }

      this.timestamp = value
    });
  },

  methods: {
    onCancel () {
      this.$emit('onCloseModal')
      this.options.onClose(null)
    },

    onRegisterBark () {
      if (!this.isRunning) {
        this.count = 0
        this.elapsed = 0
        this.isRunning = true;
      }

      this.count += 1
    },

    removeBpm(key) {
      this.barks.find(b => b.key === key).remove()
    },

    onStop() {
      this.isRunning = false;
    },

    async onSave () {
      if (this.bpm > 0) {
        await this.$nosework.context.storage().collections.bark.upsert({
          key: `${this.registration}-${this.round}-${this.timestamp}`,
          timestamp: this.timestamp,
          registration: this.registration,
          round: this.round,
          count: this.bpm,
        })
      }

      if (this.bpmSelect === 1) {
        this.options.onClose(Number(this.bpmCustom))
      } else {
        this.options.onClose(Number(this.bpmAverage))
      }
      this.$emit('onCloseModal')
    }
  }
}
</script>
