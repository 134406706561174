<template>
  <v-container class="pa-0 ma-0 transparent" style="height: 100%;" fluid>
    <div class="div1">
      <slot name="content" />
    </div>

    <v-footer :padless="true" absolute>
      <slot name="footer" />
    </v-footer>

    <component
      v-if="modal"
      :is="modal.type"
      :options="modal.options"
      @onCloseModal="onCloseModal"
    />
  </v-container>
</template>

<script>
import VueScroll from 'vuescroll'

import _ from 'lodash'

import ConfirmationModal from '@/components/confirmation-modal'
import AlertModal from '@/components/alert-modal'
import AddEditModal from '@/components/registration/timer/round-timer/add-edit-modal'
import EvaluationModal from '@/components/registration/timer/round-timer/evaluation-modal'
import BarkFrequencyModal from '@/components/registration/timer/round-timer/bark-frequency-modal'

export default {
  name: 'LayoutSingle',
  components: {
    AlertModal,
    ConfirmationModal,
    EvaluationModal,
    BarkFrequencyModal,
    AddEditModal,
    VueScroll
  },

  computed: {
    modal () {
      return _.last(this.modals)
    }
  },

  props: {
    modals: { type: Array, default: () => [] }
  },

  methods: {
    onCloseModal () {
      this.$emit('onCloseModal')
    }
  }
}
</script>

<style lang="scss">
  .__view {
    display: flex !important;
  }
</style>
