<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <div class="white">
      <div class="pa-4 pb-1">
        <h5 class="text-h5 pt-3 pb-0">
          {{ $t(roundStep.title) }}
          <span :inner-html.prop="elapsed | durationNoSemicolons" />
        </h5>
      </div>

      <smooth-time
        :timestamp="timestampForSmoothTime"
        :mintime="getMinTime"
        :maxtime="nextTimestamp ? nextTimestamp : Date.now()"
        @input="setTimestamp"
      />

      <evaluation-inputs
        v-if="evaluation"
        ref="evaluation"
        :inputs="evaluation.inputs"
        :title="evaluation.title"
        :values="currentStep.results"
        class="pl-4 pr-4"
      />
    </div>

    <v-card-actions class="pa-4 white">
      <v-btn
        elevation="0"
        right
        text
        class="grey white--text"
        xLarge
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-spacer />

      <v-btn
        v-if="(!nextTimestamp || currentStep.additional) && !options.new"
        right
        text
        class="red white--text"
        xLarge
        @click="onDelete"
      >
        {{ $t('Delete') }}
      </v-btn>

      <v-spacer />

      <v-btn
        right
        text
        class="blue white--text"
        xLarge
        @click="onSave"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
import SmoothTime from '@/components/registration/smoothTime.vue'
import EvaluationInputs from '@/components/evaluation/inputs.vue'

import moment from 'moment'
import _ from 'lodash'

import 'vue-smooth-picker/dist/css/style.css'

export default {
  name: 'AddEditModal',

  components: {
    SmoothTime,
    EvaluationInputs
  },

  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    dialog: true,
    timestamp: 0,
  }),

  computed: {
    timestampForSmoothTime() {
        if(this.currentStep.timestamp && !this.options.currentStep) {
          return this.currentStep.timestamp;
        }

        return this.previousTimestamp;
    },
    getMinTime() {
      if(!this.isTimestamp || this.roundStep.when !== undefined) {
        return this.previousTimestamp;
      }

      if(this.currentStep.timestamp === this.firstTimestamp){
          return 0;
      }

      return this.firstTimestamp;
    },
    evaluation () {
      const step = this.roundStep
      return step ? step.evaluation : undefined
    },

    firstTimestamp () {
      const step = _.first(this.options.roundSteps)
      return step ? step.timestamp : this.timestamp
    },

    previousTimestamp () {
      let idx = this.options.selectedIndex

      if (this.options.new) {
        idx = this.options.roundSteps.length
      }

      let step = this.options.roundSteps[idx - 1]

      if (this.options.currentStep) {
        if (this.roundStep.when) {
          step = this.options.roundSteps.find(
            (s) => this.roundStep.when.includes(s.state)
          )
        } else {
          step = _.last(this.options.roundSteps)
        }
      }

      return step ? step.timestamp : undefined
    },

    currentStep () {
      if (this.options.new) {
        if (this.options.currentStep) {
          return this.options.steps.find((s) => s.state === this.options.currentStep)
        } else {
          return this.options.steps[this.options.currentStepId + 1] ?? null
        }
      }

      return this.options.roundSteps[this.options.selectedIndex] ?? undefined
    },

    isTimestamp () {
      const step = this.roundStep
      return step ? step.timestamp ?? false : false
    },

    nextTimestamp() {
      const step = this.options.roundSteps[this.options.selectedIndex + 1]
      return step ? step.timestamp : undefined
    },

    roundStep () {
      return this.options.steps.find(
        step => step.state === this.currentStep.state
      ) ?? {
        title: this.currentStep.state
      }
    },

    elapsed () {
      return this.$nosework.elapsed(
        {
          ...this.currentStep,
          timestamp: this.timestamp
        },
        this.options.roundSteps,
        this.options.selectedIndex
      )
    },
  },

  mounted () {
    window.dispatchEvent(new Event('resize'))
  },

  methods: {
    /* onNext () { */
    /*   this.currentStep += 1 */
    /* }, */

    onCancel () {
      this.$emit('onCloseModal')
      this.options.onClose(null)
    },

    onDelete () {
      this.$emit('onCloseModal')
      this.options.onClose({
        index: this.options.selectedIndex,
        delete: true,
      })
    },

    onSave () {
      this.$emit('onCloseModal')
      let results = []

      if (this.evaluation) {
        results = this.$refs.evaluation.results()
      }

      this.options.onClose({
        ...this.currentStep,
        index: this.options.selectedIndex,
        elapsed: this.elapsed,
        timestamp: this.timestamp,
        results,
      })
    },

    setTimestamp(value) {
      this.timestamp = value
    }
  }
}
</script>
