<template>
  <v-container>
    <v-row>
      <v-col>
        <v-menu
          v-model="dateMenu"
          :closeOnContentCick="false"
          :nudgeRight="40"
          lazy
          transition="scale-transition"
          offsetY
          fullWidth
          maxWidth="290px"
          minWidth="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              :value="dateDisp"
              :label="$t('Date')"
              prependIcon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="time.date"
            locale="sv-se"
            :max="maxdate"
            :min="mindate"
            noTitle
            @input="dateChange"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <!-- <smooth-picker ref="smoothPicker" :data="data" :change="onChange" /> -->
      </v-col>
    </v-row>
    <v-row v-if="data[0]">
      <v-col sm="1" class="mr-0 pr-0">
        <v-icon>mdi-clock</v-icon>
      </v-col>
      <v-col class="ml-0 pl-0">
        <v-autocomplete
          dense
          auto-select-first
          v-model="time.hour"
          :items="data[0].list"
          @blur="onChange(0, time.hour)"
          @change="onChange(0, $event)"
          :label="$t('hour')"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          dense
          auto-select-first
          v-model="time.minute"
          :items="data[1].list"
          @blur="onChange(1, time.minute)"
          @change="onChange(1, $event)"
          :label="$t('minute')"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          dense
          auto-select-first
          class="muted"
          v-model="time.second"
          :items="data[2].list"
          @blur="onChange(2, time.second)"
          @change="onChange(2, $event)"
          :label="$t('second')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'

export default {
  props: {
    showSeconds: {
      type: Boolean,
      default: true
    },

    mintime: {
      type: Number,
      default: () => moment().subtract(1,'year').startOf('day').valueOf()
    },

    timestamp: {
      type: Number,
      default: Date.now()
    },

    maxtime: {
      type: Number,
      default: Date.now()
    },
  },

  data: () => ({
    dateMenu: false,
    mindate: null,
    maxdate: null,
    time: {
      hour: 0,
      minute: 0,
      second: 0,
      date: null,
    },
    data: []
  }),

  computed: {
    dateDisp() {
      return this.time.date;
    }
  },

  beforeMount () {
    const current = moment(this.timestamp)

    this.mindate = moment(this.mintime).format('YYYY-MM-DD')
    this.maxdate = moment(this.maxtime).format('YYYY-MM-DD')

    this.time = {
      hour: current.hour(),
      minute: current.minute(),
      second: current.second(),
      date: current.format('YYYY-MM-DD')
    }

    this.data = [
      this.setValues(0, this.time.hour),
      this.setValues(1, this.time.minute),
      this.setValues(2, this.time.second)
    ]
  },

  mounted () {
    this.newTime()
  },

  methods: {
    hourlimit () {
      if (
        this.time.date === this.mindate &&
        this.time.date === this.maxdate
      ) {
        return 1 // Full limit
      }

      if (this.time.date === this.mindate) {
        return 2 // Min limit
      }

      if (this.time.date === this.maxdate) {
        return 3 // max limit
      }

      return 0
    },

    minutelimit () {
      const min = moment(this.mintime)
      const max = moment(this.maxtime)

      if (
        this.time.date === this.mindate &&
        this.time.date === this.maxdate &&
        this.time.hour === min.hour() &&
        this.time.hour === max.hour()
      ) {
        return 1 // Full limit
      }

      if (
        this.time.date === this.mindate &&
        this.time.hour === min.hour()
      ) {
        return 2 // Min limit
      }

      if (
        this.time.date === this.maxdate &&
        this.time.hour === max.hour()
      ) {
        return 3 // max limit
      }

      return 0
    },

    secondlimit () {
      const min = moment(this.mintime)
      const max = moment(this.maxtime)

      if (
        this.time.date === this.mindate &&
        this.time.date === this.maxdate &&
        this.time.minute === min.minute() &&
        this.time.minute === max.minute() &&
        this.time.hour === min.hour() &&
        this.time.hour === max.hour()
      ) {
        return 1 // Full limit
      }

      if (
        this.time.date === this.mindate &&
        this.time.minute === min.minute() &&
        this.time.hour === min.hour()
      ) {
        return 2 // Min limit
      }

      if (
        this.time.date === this.maxdate &&
        this.time.minute === max.minute() &&
        this.time.hour === max.hour()
      ) {
        return 3 // max limit
      }

      return 0
    },
    newTime () {
      const newts = moment(this.time.date)

      newts.hour(this.time.hour)
      newts.minute(this.time.minute)
      newts.second(this.time.second)

      this.$emit('input', newts.valueOf())
    },

    dateChange() {
      this.dateMenu = false

      this.data = [
        this.setValues(0, this.time.hour),
        this.setValues(1, this.time.minute),
        this.setValues(2, this.time.second)
      ]

      this.newTime()
    },

    setValues(gIndex, nIndex) {
      const { time  } = this

      let type = 'hour'
      let limit = this.hourlimit()
      let maxValue = 24

      switch (gIndex) {
        case 1:
          type = 'minute'
          limit = this.minutelimit()
          maxValue = 60
          break
        case 2:
          type = 'second'
          limit = this.secondlimit()
          maxValue = 60
          break
        default:
          break
      }

      const min = moment(this.mintime)
      const max = moment(this.maxtime)

      let range = []

      switch (limit) {
        case 1:
          range = _.range(eval(`min.${type}()`), eval(`max.${type}() + 1`))
          break
        case 2:
          range = _.range(eval(`min.${type}()`), maxValue)
          break
        case 3:
          range = _.range(0, eval(`max.${type}() + 1`))
          break
        default:
          range = _.range(0, maxValue)
          break
      }

      if (range.length === 0) {
        range = [nIndex]
      }

      const clamped = _.clamp(nIndex, range[0], range[range.length - 1])
      const currentIndex = clamped
      const data = { currentIndex, list: range }

      Vue.set(time, type, clamped)

      if (this.data[gIndex]) {
        this.data[gIndex] = data

          if (gIndex === 0 && this.data[1]) {
            this.data[1] = this.onChange(1, this.time.minute)
          } else if (gIndex === 1 && this.data[2]) {
            this.data[2] = this.onChange(2, this.time.second)
          }
      }

      return data
    },

    onChange (gIndex, iIndex) {
      let data = {}

      if (this.data[gIndex] && this.data[gIndex].list) {
        data = this.setValues(gIndex, Number(iIndex))
        this.newTime()
      }

      return data
    }
  },
}
</script>

<style>
.muted {
  opacity: 0.2;
  transition: 0.4s;
}
.muted:hover,
.muted:active,
.muted:focus-visible,
.muted:focus-within,
.muted:focus {
  opacity: 1;
}
</style>
